import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/material";

export default function GetBlackList({ pb }) {
  const [datarealtime, Setdatarealtime] = React.useState([]);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    pb.collection("blackList").subscribe("*", (e) => {
      Setdatarealtime(e.record);
    });
  }, []);

  React.useEffect(() => {
    pb.collection("blackList")
      .getFullList({
        filter: 'field = false',
      })
      .then((ait) => {
        const res = ait.map((item) => ({
          id: item.id, // Необходимо указать уникальное поле как id
          name: item.name,
        }));
        setData(res);
      });
  }, [datarealtime]);

  const columns = [
    { field: "name", headerName: "ЧЕРНЫЙ СПИСОК ПОСТАВЩИКА ⤵", width: 340 },
  ];

  return (
    <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={2}
    // sx={{
    //   bgcolor: "background.paper",
    //   p: 2,
    //   borderRadius: "8px",
    //   minWidth: 360,
    // }}
  >
    <div style={{ height: 580, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        hideFooter 
        disableColumnMenu 
        disableColumnSelector
        disableSelectionOnClick 

        disableColumnFilter 
      />
    </div>
    </Stack>
  );
}
